gv.home.parallax = {

  init: function() {

    var p = this;

    this.build = function() {
      p.bind.header();
      p.bind.featuredProduct();
      p.bind.gvcBird();
      p.bind.gvcReveal();
      //p.bind.whyOrganics();

      return p;
    };

    return this.build();
  },

  bind: {
    header: function() {
      var controller = new ScrollMagic.Controller({
            globalSceneOptions: { 
              triggerHook: 'onLeave', duration: '100%' 
            },
            refreshInterval : 0
          }),
          scene = new ScrollMagic.Scene({ triggerElement: '#section-header' }),
          tween = new TimelineLite(),
          timelineTruck = new TimelineLite(),
          timelineCow = new TimelineMax(),
          timelineCowTwo = new TimelineMax(),
          timelineBird = new TimelineMax(),
          timelineSign = new TimelineMax();

      tween.add([
        TweenLite.to('#home-header-bg-sun', 1, { y: '80%', ease: Linear.easeNone }),
        TweenLite.to('#home-header-bg-mountains', 1, { y: '60%', ease: Linear.easeNone }),
        TweenLite.to('#home-header-bg-plains', 1, { y: '60%', ease: Linear.easeNone }),
        TweenLite.to('#home-header-bg-hills', 1, { y: '50%', ease: Linear.easeNone }),
        TweenLite.to('#home-header-sign', 1, { y: '40%', ease: Linear.easeNone }),
        TweenLite.to('#home-header-bird', 1, { y: '30%', ease: Linear.easeNone })
      ]);

      timelineTruck.set('#home-header-truck', { left: '30%', top: '40%', xPercent: -50, yPercent: -50, opacity: 1, scale: 1.5, ease: Linear.easeNone })
        .to('#home-header-truck', 3.5, { left: '23.5%', top: '18%', scale: 1.1, ease: Linear.easeNone }, '+=2')
        .to('#home-header-truck', 2, { left: '19%', top: '12%', scale: 0.8, ease: Linear.easeNone })
        .to('#home-header-truck', 2, { left: '19.5%', top: '8%', scale: 0.6, ease: Linear.easeNone })
        .to('#home-header-truck', 2, { left: '21%', top: '5%', scale: 0.30, ease: Linear.easeNone })
        .to('#home-header-truck', 2, { left: '21.5%', top: '6.1%', scale: 0.1, opacity: 0, ease: Linear.easeNone });

      timelineCow.set('#home-header-cow', { left: '80%', top: '18%' })
        .to('#home-header-cow', 14, { left: '93%', delay: 2, ease: Linear.easeNone });

      timelineCowTwo.to('#home-header-cow img', 0.25, { rotationZ: '2deg', ease: Linear.easeNone, delay: 2, repeat: 55, yoyo: true });

      timelineSign.set('#home-header-sign-img', { y: '100%' })
        .to('#home-header-sign-img', 2, { y: '0%', delay: 1, ease: Back.easeOut.config(0.25) });

      timelineBird.to('#home-header-bird', 20, { left: '125%', ease: Linear.easeNone });

      jQuery('#home-header-bird .bird').addClass('show-banner is-flying is-flying--no-jitter');

      scene.setTween(tween);
      //scene.addIndicators();
      scene.addTo(controller);
    },
    featuredProduct: function() {
      var $bird = jQuery('#home-product .bird'),
          controller = new ScrollMagic.Controller({
            globalSceneOptions: { 
              triggerHook: 0.50
            }
          }),
          scene = new ScrollMagic.Scene({ 
            triggerElement: '#home-product',
            reverse: false,
          }),
          tween = new TimelineLite({
            onUpdate: function(self) {
              if(self.totalProgress() > 0.80 && $bird.hasClass('is-flying')) {
                $bird.removeClass('is-flying');
              }

              if(self.totalProgress() >= 1) {
                setTimeout(function() {
                  if(!$bird.hasClass('is-bouncing')) {
                    $bird.addClass('is-bouncing');
                  }
                }, 2000);
              }
            },
            onUpdateParams:["{self}"]
          });

      tween.set($bird, { left: '-150%', top: '-40%', scale: 0.5 });

      $bird.addClass('is-flying');

      var bird = new gv.bird($bird, {
        message: 'Hey, over here!'
      });

      tween.to($bird, 2, { left: '-15%', top: '50%', scale: 0.80, ease: Linear.easeNone })
        .to($bird, 0.25, { left: '0%', top: '45%', scale: 0.80, ease: Linear.easeNone })
        .to($bird, 0.5, { left: '4.0%', top: '42%', scale: 1, ease: Power1.easeOut, onComplete:function(){

          $bird.addClass('show-bubble');

          setTimeout(function(){

            $bird.removeClass('show-bubble');

            setTimeout(function(){

              var bird = new gv.bird($bird, {
                message: 'Check out our recipe for Black Bean and Chicken Tostadas!'
              });

              var bird_url = jQuery('.bird').data("bird-link");

              jQuery('.bird').addClass('pointer');

              jQuery('.bird').click(function() {
                window.location = bird_url;
              });

            }, 1000);

          }, 4000);

        } });
      

      scene.setTween(tween);
      //scene.addIndicators();
      scene.addTo(controller);
    },
    gvcReveal: function() {
      var controller = new ScrollMagic.Controller({
            globalSceneOptions: { 
              triggerHook: 'onEnter',
              duration: '100%'
            }
          }),
          scene = new ScrollMagic.Scene(),
          tween = new TimelineLite(),
          getOffset = function() {
            return jQuery('#home-gvc-revealer').offset().top - jQuery(window).height();
          },
          resizedw = function(){
            var progress = tween.progress();

            TweenLite.set('#home-fiveDollarPlug', { y: '0%', ease: Linear.easeNone });
            TweenLite.set('#home-gvc-container', { y: '0%', ease: Linear.easeNone });
            
            scene.offset(getOffset());
            
            if(progress === 0) {
              TweenLite.set('#home-gvc-container', { y: '-10%', ease: Linear.easeNone });
            }

            scene.update();
          },
          doit;

      scene.offset(getOffset());

      TweenLite.set('#home-gvc-container', { y: '-10%', ease: Linear.easeNone });

      tween.add([
        TweenLite.to('#home-fiveDollarPlug', 1, { y: '-40%', ease: Linear.easeNone }),
        TweenLite.to('#home-gvc-container', 1, { y: '0%', ease: Linear.easeNone }),
        TweenLite.to('#home-gvc-field-bg-sun', 1, { y: '125%', ease: Linear.easeNone }),
        TweenLite.to('#home-gvc-field-bg-silhouettes', 1, { y: '10%', ease: Linear.easeNone }),
        TweenLite.to('#home-gvc-field-bg-mountains', 1, { y: '8%', ease: Linear.easeNone }),
        TweenLite.to('#home-gvc-field-bg-plains', 1, { y: '6%', ease: Linear.easeNone }),
        TweenLite.to('#home-gvc-field-bg-farm', 1, { y: '4%', ease: Linear.easeNone }),
        TweenLite.to('#home-gvc-field-bg-hills', 1, { y: '3%', ease: Linear.easeNone }),
      ]);

      jQuery('#home-gvc-container .bird').addClass('is-flipped');

      jQuery(window).on('resize', function() {
        clearTimeout(doit);
        doit = setTimeout(resizedw, 100);
      });

      scene.setTween(tween);
      //scene.addIndicators();
      scene.addTo(controller);
    },
    gvcBird: function() {
      var $bird = jQuery('#home-gvc .bird'),
          controller = new ScrollMagic.Controller({
            globalSceneOptions: { 
              triggerHook: 0.75
            }
          }),
          scene = new ScrollMagic.Scene({ 
            triggerElement: '#home-gvc-content-container',
            reverse: false,
          }),
          tween = new TimelineLite({
            onUpdate: function(self) {

            },
            onUpdateParams:["{self}"]
          });

      var bird = new gv.bird($bird, {
        message: 'Tweet tweet! Tweet us at @itsgreenvalley.'
      });

      tween.set($bird, { x: -645, y: 27 });

      tween.to(

        $bird, 1.0, { x: -150, y: 22, ease: Linear.easeNone })
        .to($bird, 0.125, { x: -125, y: 1, ease: Linear.easeNone })

        .to($bird, 0.1, { x: -105, y: 12, ease: Linear.easeNone })
        .to($bird, 0.125, { delay:1.25, x: -85, y: -3, ease: Linear.easeNone })
        
        .to($bird, 0.1, { x: -65, y: 4, ease: Linear.easeNone })
        .to($bird, 0.125, { x: -45, y: -4, ease: Linear.easeNone })
        
        .to($bird, 0.1, { x: -25, y: 2, ease: Linear.easeNone })
        .to( $bird, 0.125, { x: -10, y: -6, ease: Linear.easeNone });



      // tween.to($bird, 0.125, { x: -5, y: -5, ease: Linear.easeNone })
      //   .to($bird, 0.1, { x: -25, y: 15, ease: Linear.easeNone })

      //   .to($bird, 0.125, { x: -45, y: -3, ease: Linear.easeNone })
      //   .to($bird, 0.1, { x: -65, y: 20, ease: Linear.easeNone })

      //   .to($bird, 0.125, { x: -85, y: -1, ease: Linear.easeNone })
      //   .to($bird, 0.1, { x: -105, y: 25, ease: Linear.easeNone })

      //   .to($bird, 0.125, { x: -125, y: 1, ease: Linear.easeNone })
      //   .to($bird, 0.1, { x: -145, y: 27, ease: Linear.easeNone });

      scene.setTween(tween);
      //scene.addIndicators();
      scene.addTo(controller);
    },
    whyOrganics: function() {
      var controller = new ScrollMagic.Controller({
            globalSceneOptions: { 
              triggerHook: 0.5,
              duration: '100%' 
            }
          }),
          scene = new ScrollMagic.Scene({ triggerElement: '#home-why-organics' }),
          tween = new TimelineLite();

      TweenLite.set('footer', { y: '-85%', ease: Linear.easeNone });

      tween.add([
        TweenLite.to('footer', 1, { y: '0%', ease: Linear.easeNone }),
      ]);

      scene.setTween(tween);
      //scene.addIndicators();
      scene.addTo(controller);
    },
  },

};
