gv.healthyEating = {

  init: function($) {
    var he = this;

    this.build = function() {
      he.bind();
      he.animations.init();

      return he;
    };

    this.bind = function() {

    };

    this.ready = function () {

    };

    this.resize = function() {

    };

    return this.build();
  },

};