
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
 debug.enable('*');
 debug.disable("engine.io-client");

function detectmob() {
 if( navigator.userAgent.match(/Android/i)
 || navigator.userAgent.match(/webOS/i)
 || navigator.userAgent.match(/iPhone/i)
 || navigator.userAgent.match(/iPad/i)
 || navigator.userAgent.match(/iPod/i)
 || navigator.userAgent.match(/BlackBerry/i)
 || navigator.userAgent.match(/Windows Phone/i)
 ){
    return true;
  }
 else {
    return false;
  }
}

(function($) {
  var logger = debug('main');
  logger('ready');

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        logger('common.init');

        if(detectmob()) {
          $('body').addClass('mobile');
        } else {
          $('body').addClass('desktop');
        }

        $('.sidemenu-toggle').click(function(e){
          e.preventDefault();
          $('body').toggleClass('sidemenu-open');

          if($('body').hasClass('sidemenu-open')) {
            jQuery(document).on('touchmove', function(e) {
                e.preventDefault();
            });
          } else {
            jQuery(document).off('touchmove');
          }

        });

        $('.content-blocker').click(function( event ) {
          $('body').removeClass('sidemenu-open');
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        logger('common.finalize');

        // Community posts
        var renderCommpunityPosts = function(){
          $('.community-post').each(function(index, el){
            var $el = $(el);
          });
        };

        // Bootstrap opt ins
        $('[data-toggle="tooltip"]').tooltip();

        $('#main_nav').scrollToFixed();

        if(!detectmob()) {

          // product filter code
          // make this conditional on mobile - should click through the page for mobile
          // -------------------------------------------
          $(".product-filter").click(function( event ) {
              event.preventDefault();
              var el = $(this);
              var selection = $(this).data("product-sel");
              $(".product-filter").removeClass('selected');
              $('.product-item').hide();

              if(selection === 'all') {
                $('.product-item').show();
              } else {
                $('.'+selection).show();
              }

              el.addClass('selected');
          });
          // --------------------------------------------


          // product filter code
          // make this conditional on mobile - should click through the page for mobile
          // -------------------------------------------
          $(".recipe-filter").click(function( event ) {
              event.preventDefault();
              var el = $(this);
              var selection = $(this).data("recipe-sel");
              $(".recipe-filter").removeClass('selected');
              $('.recipe-filter-item').hide();

              if(selection === 'all') {
                $('.recipe-filter-item').show();
              } else {
                $('.'+selection).show();
              }

              el.addClass('selected');
          });
          // --------------------------------------------

        }

        // image swapper functionality for the product detail page
        // -------------------------------------------
        $(".detail-image-swap").click(function( event ) {

          event.preventDefault();
          var el = $(this);
          var image_url = $(this).data("image-url");
          $(".detail-image-swap").removeClass('selected');
          el.addClass('selected');

          $('.featured-image').html('<img class="img-responsive" src="'+image_url+'" alt="">');


        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        logger('home.finalize()');

        $("body").addClass("fade-in");

        if($('body').hasClass('mobile')){
          $('.home #header-mobile').scrollToFixed();
        }

        var currentSliderPosition = 0;

        var sync1 = $("#heroProduct");

        var sync2 = $("#productsCarousel");

        sync1.owlCarousel({
          singleItem : true,
          slideSpeed : 200,
          navigation: false,
          pagination:false,
          lazyLoad : true,
          responsiveRefreshRate : 200,
          items : 1, //10 items above 1000px browser width
          itemsDesktop : [1000,1], //5 items between 1000px and 901px
          itemsDesktopSmall : [900,1], // betweem 900px and 601px
        });

        sync2.owlCarousel({
          items : 6,
          responsiveClass:true,
          responsive:{
              0:{
                  items:3,
                  nav:true
              },
              600:{
                  items:4,
                  nav:false
              },
              960:{
                  items:6,
                  nav:true,
                  loop:false
              }
          },
          nav: false,
          pagination:true,
          responsiveRefreshRate : 100
        });

        $('#owl_item_0').addClass('selected');

        sync1.on('translated.owl.carousel', function(event) {

            console.log(currentSliderPosition);

            $('.hero-product').removeClass('selected');
            $('#owl_item_'+currentSliderPosition).addClass('selected');
        });

        $(".product-list-container .product-item").on("click", function(e){
          e.preventDefault();

          var number = $(this).attr("id");
          currentSliderPosition = number;
          sync1.trigger("to.owl.carousel", currentSliderPosition);

          var target = $('a[name=product-scroller]');

          $('.product-item').removeClass('selected');
          $('#'+currentSliderPosition).addClass('selected');

          // $('html,body').animate({
          //   scrollTop: target.offset().top - 120
          // }, 1000);

        });


        $("#home-menu .home").on("click", function(e){

          var home_target = $('a[name=home]');

          $('html,body').animate({
            scrollTop: home_target.offset().top - 120
          }, 1000);

        });

        // Custom Navigation Events
        $('.carousel-next').click(function(){
          currentSliderPosition++;
          sync2.trigger("next.owl.carousel");
        });

        $('.carousel-prev').click(function(){
          currentSliderPosition--;
          sync2.trigger("prev.owl.carousel");
        });

        // need to delay it so the images load
        //
        setTimeout( function(){

          var $container = $('.social-grid');

          $container.packery({
            itemSelector: '.item',
            gutter: 20
          });

        }, 500 );


        //&& $('html').hasClass('ie9') === false

        if(!detectmob() ) {
          var home = gv.home.init($);
        }

        if(!detectmob()) {
          $('#home-menu').scrollToFixed();
        }

      }
    },

    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // Product archive page
    'product': {
      init: function() {
        // JavaScript to be fired on the product page
        logger('product.finalize()');

      },
    },
    // Product archive page
    'post_type_archive_product': {
      init: function() {
        // JavaScript to be fired on the product archive page
        logger('product.finalize()');
      },
      finalize: function() {

        if(!detectmob()) {
          gv.products.init($);
        }

      },
    },

    'gv_community':{
      init: function() {

        // need to delay it so the images load
        //
        setTimeout( function(){

          var $container = $('.social-grid');
          $container.packery({
            itemSelector: '.item',
            gutter: 20
          });

          gv.gvc.init($);

        }, 200 );


      }
    },

    'healthy_eating':{
      finalize: function() {
        gv.healthyEating.init($);
      },
    },
  };


  var getUrlParameter = function getUrlParameter(sParam) {
      var sPageURL = decodeURIComponent(window.location.search.substring(1)),
          sURLVariables = sPageURL.split('&'),
          sParameterName,
          i;

      for (i = 0; i < sURLVariables.length; i++) {
          sParameterName = sURLVariables[i].split('=');

          if (sParameterName[0] === sParam) {
              return sParameterName[1] === undefined ? true : sParameterName[1];
          }
      }
  };



  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
