function ProductLocator(opts) {
  var me = this;
  var errors = [];
  
  if(opts && opts.debug) {
    var debug = opts.debug;
  }
  else {
    var debug = false;
  }

  var fields = {
    zip: {
      selector: "#zip",
      error_selector: "#error-zip",
      value: "",
      validationType: "regex",
      regex: /(^\d{5}$)|(^\d{5}-\d{4}$)/, 
      errorMessage: "Zip is not the correct format"
    },
    item: {
      selector: "#item",
      error_selector: "#error-item",
      value: "", 
      validationType: "wrong-value", 
      errorMessage: "Please choose an item"
    }, 
    radius: {
      selector: "#radius",
      error_selector: "#error-radius",
      value: "", 
      validationType: "blank", 
      errorMessage: "You must select a radius"
    }
  };

  var openSelect = function(selector){
      var element = jQuery(selector)[0], worked = false;
      if (document.createEvent) { // all browsers
          var e = document.createEvent("MouseEvents");
          e.initMouseEvent("mousedown", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
          worked = element.dispatchEvent(e);
      } else if (element.fireEvent) { // ie
          worked = element.fireEvent("onmousedown");
      }
      if (!worked) { // unknown browser / error
          alert("It didn't worked in your browser.");
      }   
  }


  var setupSelects = function() {
      jQuery('#item .arrow').click(function() {
        console.log('hi');
        openSelect(jQuery('#item'));
      });
  }

  

  var Gmap = function(opts, productLocator) {
    var me = this;
    var debug = opts.debug;
    var apiKey = "AIzaSyC2fPe4Q6usjXpDK19zpfHGMXP-JDTtDgI";
    var map;
    var s = document.createElement("script");
    var markers = [];
    var infoWindows = [];
    var mapInit = false;
    s.type = "text/javascript";
    s.async = true;
    s.defer = true;
    s.src = "https://maps.googleapis.com/maps/api/js?key=" + apiKey + "&callback=productLocator.gmapInit";
    jQuery("head").append(s);

    var resize = function() {
      google.maps.event.trigger(map, 'resize');
    }

    var removeMarkers = function() {
      for(var i = 0; i < markers.length; i++) {
        var marker = markers[i];
        marker.setMap(null);
      }
      markers = [];
    }

    var removeInfoWindows = function() {
      infoWindows = [];
    }

    var closeAllInfoWindows = function() {
      for(var i = 0; i < infoWindows.length; i++) {
        var infoWindow = infoWindows[i];
        infoWindow.close();
      }
    }

    var infoWindowTemplate = function(store) {
      var html = "<div class='product-locator-gmap-info-window'>";
      html    +=  "<div class='field-row'>" + store.name + "</div>";
      html    +=  "<div class='field-row'>" + productLocator.combinedAddress(store) + "</div>";
      html    +=  "<div class='field-row'>" + store.city + "," + store.zip + "</div>";
      html    += "<div>";
      return html;
    }

    me.init = function() {
     
    }
    
    me.setMarkersAndResize = function(stores) {
      if(mapInit == false) {
        map = new google.maps.Map(document.getElementById('storemap'), {
          center: new google.maps.LatLng(0.0, 0.0),
          zoom: 7
        });
        mapInit = true;
      }
      removeMarkers();
      removeInfoWindows();
      var bounds = new google.maps.LatLngBounds();
      for(var i = 0; i < stores.length; i++) {
        var store = stores[i];
        var latlng = new google.maps.LatLng(store.latitude, store.longitude);
        var infoWindow = new google.maps.InfoWindow({ content: infoWindowTemplate(store) });
        var n = infoWindows.push(infoWindow);
        var marker = new google.maps.Marker({
          position: latlng,
          animation: google.maps.Animation.DROP,
          title: store.name, 
          infoWindow: infoWindow
        });
        var k = markers.push(marker);
        google.maps.event.addListener(marker, 'click', function() {
          closeAllInfoWindows();
          this.infoWindow.open(map, this);
        });
        bounds.extend(marker.position);
        marker.setMap(map);
      }
      map.fitBounds(bounds);
      resize();
    }
  }

  var gmap = new Gmap({
    debug: debug
  }, me);

  me.gmapInit = gmap.init;

  var API = function(opts) {
    var me = this;
    var urlBase = "http://www2.itemlocator.net/ils/locatorJSON/";
    var urlLocal = "/wp-content/themes/greenvalley-wordpress-theme/get_stores.php";
    var debug = opts.debug;

    var params = {
      customer: "senecaweb", 
      item: "",
      zip: "",
      radius: ""
    };

    me.params = params;

    var queryString = function() {
      var s = "?";
      for(param in params) {
        var v = params[param];
        if(v.toString().length > 0) {
          s += param + "=" + v + "&";
        }
      }
      // get rid of trailing '&'
      s = s.substring(0, s.length - 1);
      return s;
    }

    me.setParam = function(param, value) {
      if(param in params) {
        params[param] = value;
      }
      else {
        throw "ProductLocator: Tried to set param '" + param + "'. This is not a valid param";
      }
    }

    me.getStores = function(callback) {
      var fullUrl = urlBase + queryString();
      jQuery.post(urlLocal, {
          url: fullUrl
        },
        function(data) {
          var jsonData = JSON.parse(data);
          if(debug == true) {
            console.info("RAW DATA FROM API: " + data);
            console.info(jsonData);
          }
          if(jsonData.status && jsonData.status == "success") {
            if(callback) {
              callback(jsonData.data);
            }
          }
          else {
            if(callback) {
              callback();
            }
          }
      });
    }
  };

  var api = new API({
    debug: debug
  });

  var validate = function() {
    for(field in fields) {
      var f = fields[field];
      var v = jQuery(f.selector).val();

      if(f.validationType == "blank") {
        if(v.toString().length == 0) {
          var o = {};
          o[f.error_selector] = f.errorMessage;
          errors.push(o);
        } 
        else {
          fields[field].value = v;
        }
      }
      else if(f.validationType == "regex") {
        if(f.regex.test(v) == false) {
          var o = {};
          o[f.error_selector] = f.errorMessage;
          errors.push(o);
        }
        else {
          fields[field].value = v;
        }
      } 
      else if(f.validationType == "wrong-value") {
        if(v.toString() == "0") {
          var o = {};
          o[f.error_selector] = f.errorMessage;
          errors.push(o);
        } 
        else {
          fields[field].value = v;
        }
      }
    }
  }

  var showErrors = function() {
    var s = "ERRORS: \n";
    for(var i = 0; i < errors.length; i++) {
      var error = errors[i];
      s += JSON.stringify(error) + "\n";
      for(selector in error) {
        jQuery(selector).html(error[selector]).addClass("show-error");
      }
    }
    jQuery(".show-error").fadeIn("fast");
    errors = [];
  }

  var fullAddress = function(store) {
    if(store.address2.length > 0) {
      var a = store.address + " " + store.address2;
    }
    else {
      var a = store.address;
    }
    return a + " " + store.city + ", " + store.state + " " + store.zip;
  }

  var combinedAddress = function(store) {
    if(store.address2.length > 0) {
      var a = store.address + " " + store.address2;
    }
    else {
      var a = store.address;
    }
    return a;
  }

  me.fullAddress = fullAddress;

  me.combinedAddress = combinedAddress;

  var storeRowTemplate = function(store) {
    var template = '<li>';
    template +=    '<div class="col-md-8 trans-bg">';
    template +=    '<div class="product-locator-spacer visible-xs-block visible-sm-block"></div>';
    template +=    '<div class="inner">';
    template +=    '<div class="name">' + store.name + '</div>';
    template +=    '<div class="address">' + fullAddress(store) + '</div>';
    template +=    '</div></div>';
    template +=    '<div class="col-md-2 trans-bg">';
    template +=    '<div class="product-locator-spacer visible-xs-block visible-sm-block"></div>';
    template +=    '<div class="inner">';
    template +=    '<div class="distance">' + store.distance + ' mile(s)</div>';
    template +=    '</div></div>';
    template +=    '<div class="col-md-2">';
    template +=    '<div class="product-locator-spacer visible-xs-block visible-sm-block"></div>';
    template +=    '<div class="inner">';
    template +=    '<a target="_blank" href="https://maps.google.com?saddr=Current+Location&daddr=' + store.latitude + ',' + store.longitude + '" class="get-directions-btn"><div class="get-directions-btn-inner">Get Directions</div></a>';
    template +=    '</div></div>';
    template +=    '</li>';
    return template;
  }

  var showStores = function(productData, callback) {
    var stores = productData.nearbyStores;
    if(productData.numStoresFound > 0) {
      var showResults = function() {
        jQuery("#total-stores").html(productData.numStoresFound);
        jQuery("#within-radius").html(productData.radius);
        jQuery("#within-zip").html(productData.zip);
        var html = "";
        for(var i = 0; i < stores.length; i++) {
          var store = stores[i];
          html += storeRowTemplate(store);
        }
        jQuery("#results-list").html(html);
        jQuery("#product-locator-results").fadeIn("fast", function() {
          gmap.setMarkersAndResize(stores);
          if(callback) {
            callback();
          }
        });
      }
      jQuery("#product-locator-none-found").fadeOut("fast", showResults);
    }
    else {
      hideLoader(function() {
        jQuery("#product-locator-results").fadeOut("fast", function() {
          jQuery("#product-locator-none-found").fadeIn("fast");
        });
      }); 
    }
  }

  var cssOverrides = function() {
    jQuery("#header-desktop").addClass("min-height-1px");
    jQuery(".content").css({"margin-bottom": "margin-bottom:20px"});
    if(jQuery("html").hasClass("ie9")) {
      jQuery(".remove-ie9-arrow").remove();
    }
  }

  var getStores = function() {
    showLoader();
    for(field in fields) {
      api.setParam(field, fields[field].value);
    }
    api.getStores(function(productData) {
      if(productData) {
         showStores(productData, function() {
            hideLoader();
          });
      }
      else {
        hideLoader();
      }
    }); 
  }

  me.getStores = function() {
    jQuery(".show-error").hide();
    jQuery(".form-error").removeClass("show-error");
    validate();
    if(errors.length > 0) {
      showErrors();
    }
    else {
      getStores();
    }
  }

  var showLoader = function() {
    jQuery("#product-locator-loader").fadeIn("fast");
  }

  var hideLoader = function(callback) {
    jQuery("#product-locator-loader").fadeOut("fast", function() {
      if(callback) {
        callback();
      }
    });
  }

  var addLoaderToDOM = function() {
    var html = "<div style='display:none;' id='product-locator-loader'>";
    html    += "<div class='loader-inner text-center'><div class='loading-wheel'></div></div>"; 
    html    += "</div>";
    jQuery("body").prepend(html);
  }

  cssOverrides();
  addLoaderToDOM();

  //setupSelects();

  
}

var productLocator = new ProductLocator({ debug: false });