gv.gvc.animations = {

  init: function() {

    var p = this;

    this.build = function() {
      p.bind.sign();

      return p;
    };

    return this.build();
  },

  bind: {
    sign: function() {
      var $bird = jQuery('#community-posts .bird'),
          controller = new ScrollMagic.Controller({
            globalSceneOptions: { 
              triggerHook: 0.75
            }
          }),
          scene = new ScrollMagic.Scene({ 
            triggerElement: '#community-posts',
            reverse: false,
          }),
          tween = new TimelineLite({
            onUpdate: function(self) {

            },
            onUpdateParams:["{self}"]
          });

      //$bird.addClass('is-flipped');

      var bird = new gv.bird($bird, {
        message: 'Use #organicsforall and you could be featured on our page!'
      });

      tween.set($bird, { x: -645, y: 27 });

      tween.to(

        $bird, 1.0, { x: -150, y: 22, ease: Linear.easeNone })
        .to($bird, 0.125, { x: -125, y: 1, ease: Linear.easeNone })

        .to($bird, 0.1, { x: -105, y: 12, ease: Linear.easeNone })
        .to($bird, 0.125, { delay:1.25, x: -85, y: -3, ease: Linear.easeNone })
        
        .to($bird, 0.1, { x: -65, y: 4, ease: Linear.easeNone })
        .to($bird, 0.125, { x: -45, y: -4, ease: Linear.easeNone })
        
        .to($bird, 0.1, { x: -25, y: 2, ease: Linear.easeNone })
        .to( $bird, 0.125, { x: -10, y: -6, ease: Linear.easeNone });

      scene.setTween(tween);
      //scene.addIndicators();
      scene.addTo(controller);
    },
  },

};
