gv.gvc = {

  init: function($) {
    var gvc = this;

    this.build = function() {
      gvc.bind();
      gvc.animations.init();

      return gvc;
    };

    this.bind = function() {

    };

    this.ready = function () {

    };

    this.resize = function() {

    };

    return this.build();
  },

};