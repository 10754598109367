gv.home = {

  init: function($) {
    var h = this;

    this.build = function() {
      h.bind();
      h.parallax.init();

      $(window).on('load', function() {
        h.ready();
      });

      return h;
    };

    this.bind = function() {
      // $(window).on('resize', function() {
      //   h.resize();
      // });
    };

    this.ready = function () {
      //h.resize();
    };

    this.resize = function() {
      var $el = $('#home-gvc-fg-hill');
      $el.css('margin-top', -($el.height() - 3));
    };

    return this.build();
  },

};