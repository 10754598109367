gv.healthyEating.animations = {

  init: function() {

    var p = this;

    this.build = function() {
      p.bind.familyMeals();

      return p;
    };

    return this.build();
  },

  bind: {
    familyMeals: function() {
      var $bird = jQuery('#family-meals .bird'),
          controller = new ScrollMagic.Controller({
            globalSceneOptions: { 
              triggerHook: 0.50
            }
          }),
          scene = new ScrollMagic.Scene({ 
            triggerElement: '#family-meals',
            reverse: false,
          }),
          tween = new TimelineLite({
            onUpdate: function(self) {
              if(self.totalProgress() > 0.90 && $bird.hasClass('is-flying')) {
                $bird.removeClass('is-flying');
              }

              // if(self.totalProgress() >= 1) {
              //   setTimeout(function() {
              //     if(!$bird.hasClass('is-bouncing')) {
              //       $bird.addClass('is-bouncing');
              //     }
              //   }, 2000);
              // }
            },
            onUpdateParams:["{self}"]
          });

      tween.set($bird, { left: '-150%', top: '10%', scale: 0.5 });

      $bird.addClass('is-flying');

      var bird = new gv.bird($bird, {
        message: 'Big tastes. Bird budgets!'
      });

      tween.to($bird, 2, { left: '0%', top: '10%', scale: 0.80, ease: Linear.easeNone })
        .to($bird, 0.25, { left: '15%', top: '0%', scale: 0.80, ease: Linear.easeNone })
        .to($bird, 0.5, { left: '25%', top: '-12%', scale: 1, ease: Power1.easeOut });
      
      // var bird = new gv.bird($bird, {
      //   message: 'Check out a recipe!'
      // });

      scene.setTween(tween);
      //scene.addIndicators();
      scene.addTo(controller);
    },
  },

};
