gv.products.animations = {

  init: function() {

    var p = this;

    this.build = function() {
      p.bind.header();

      return p;
    };

    return this.build();
  },

  bind: {
    header: function() {

      var $menu = jQuery('#product-menu');

      // var $bird = jQuery('#section-header .bird'),
          controller = new ScrollMagic.Controller({
            globalSceneOptions: { 
              triggerHook: 0.50
            }
          }),
          scene = new ScrollMagic.Scene({ 
            triggerElement: '#section-header',
            reverse: false,
          }),
          tween = new TimelineLite({
            onUpdate: function(self) {
              // if(self.totalProgress() > 0.90 && $bird.hasClass('is-flying')) {
              //   $bird.removeClass('is-flying');
              // }

              // if(self.totalProgress() >= 1) {
              //   setTimeout(function() {
              //     if(!$bird.hasClass('is-bouncing')) {
              //       $bird.addClass('is-bouncing');
              //     }
              //   }, 2000);
              // }
            },
            onUpdateParams:["{self}"]
          });

      //tween.set($bird, { left: '-150%', bottom: '180%', scale: 0.5 });

      tween.set($menu, { left: '-200%' });

      // $bird.addClass('is-flying');

      // var bird = new gv.bird($bird, {
      //   message: '100% DELICIOUS.'
      // });

      tween.to($menu, 6.5, { left: '0%', ease: Power1.easeOut });
      
        //.to($bird, 3, { left: '17%', bottom: '-600%', scale: 0.80, ease: Linear.easeNone })
        //.to($bird, 0.5, { left: '45%', bottom: '200%', scale: 1, ease: Linear.easeNone });
        //.to($bird, 0.75, { left: '47%', bottom: '200%', scale: 1, ease: Power1.easeOut });
      
      // var bird = new gv.bird($bird, {
      //   message: 'Check out a recipe!'
      // });

      scene.setTween(tween);
      //scene.addIndicators();
      scene.addTo(controller);
    },
  },

};
