gv.bird = function($el, opts) {
  var $frame = jQuery('.bird-frame', $el),
      $msg = jQuery('.bird-bubble-text', $el);

  if(typeof opts !== 'undefined') {
    if(opts.message !== 'undefined') {
      $msg.text(opts.message);

      $frame.on('mouseover', function() {
        $el.addClass('show-bubble');
      }).on('mouseout', function() {
        $el.removeClass('show-bubble');
      });
    }
  }

  return this;
};